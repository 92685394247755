export const environment = {
  firebase: {
    apiKey: 'AIzaSyDdT69m5yjMz0GGXwzvQ5WVXjIXmk9iYDc',
    authDomain: 'maszk2-prod.firebaseapp.com',
    projectId: 'maszk2-prod',
    storageBucket: 'maszk2-prod.appspot.com',
    messagingSenderId: '797629436279',
    appId: '1:797629436279:web:a94d6680a221e9aedd9172',
    vapidKey:
      'BNCkRYRi-q4e0-x3jIA2crGTYcArr9lQbXqDAvf9Fq6_UZfGGv_cfFterFF5A9A5cf7xsWCGkIIlFfRhDqcgcfg',
  },
  cfBase: 'https://europe-west1-maszk2-prod.cloudfunctions.net/',
  production: true,
  publicRSAKey: `-----BEGIN PUBLIC KEY-----
MIIBITANBgkqhkiG9w0BAQEFAAOCAQ4AMIIBCQKCAQBc73bRlRu5cAKhqC5vZP09
byOz8bbyg5GW+7T74cSWgr9u8FqUMrGvJx/w08+J7Zbd7NOFvfWFfkcWHjDDSekc
q0+vEawJNPDiuaKjPqat5POLttyybwwZIGx/iHqeRRObvWRFVX4iWBpSRQs4WKdb
t5orZmls6ykEyR/uaA3DzYCRU8xTE+wTDa2M8ce5D8zqI4AavGN6QKsEoCnfjNgz
VbtmQdqxXpJgqi4i+oDufAhKmPwzuAAIUyWbmIZ7PGRsIJjk0POXZBQfuEJkfMTv
/+bZv53TdHUrje/wVjqJqyFZENYXmf/QL2ue6Plpim8NXdhJjKoGi9V1iYLfXhkz
AgMBAAE=
-----END PUBLIC KEY-----`,
  id: 'prod',
  region: 'europe-west1',
  version: '7.1.9',
  emulatorHost: 'localhost',
};
