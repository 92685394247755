import { Injectable } from '@angular/core';
import { LoadingController, NavController } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import 'firebase/auth';
import { FcmService } from './fcm.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private afAuth: AngularFireAuth,
    private loadingController: LoadingController,
    private navCtrl: NavController,
    private fcmService: FcmService,
    private storage: StorageService
  ) {}

  currentUserObservable(): any {
    return this.afAuth.authState;
  }

  async login(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  async passwordRemind(email: string) {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  async newPassword(newPassword: string) {
    return this.afAuth.currentUser.then(user => user?.updatePassword(newPassword));
  }

  async logout(text?: string) {
    try {
      if (text) {
        const loading = await this.loadingController.create({
          message: text,
        });
        await loading.present();
      }
      const savedToken = (await this.storage.get('fcm_token')) || null;
      if (savedToken) {
        await this.fcmService.deleteFCMtoken(savedToken);
      }
      await this.fbLogout();
      let temp = localStorage.getItem('lang')!;
      localStorage.clear();
      localStorage.setItem('lang', temp);
      if (text) {
        this.loadingController.dismiss();
        this.navCtrl.navigateRoot(['login']);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async fbLogout() {
    await this.afAuth.signOut();
  }

  /*
  async login(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  async fbLogout() {
    await this.afAuth.signOut();
  }

  async logout(text?: string) {
    try {
      if (text) {
        const loading = await this.loadingController.create({
          message: text,
        });
        await loading.present();
      }
      const savedToken = (await this.storage.get('fcm_token')) || null;
      if (savedToken) {
        await this.fcmService.deleteFCMtoken(savedToken);
      }
      await this.fbLogout();
      let temp = localStorage.getItem('lang')!;
      localStorage.clear();
      localStorage.setItem('lang', temp);
      if (text) {
        this.loadingController.dismiss();
        this.navCtrl.navigateRoot(['login']);
      }
    } catch (error) {
      console.error(error);
    }
    this.fbLogout()
      .then(async () => {
        let temp = localStorage.getItem('lang')!;
        localStorage.clear();
        localStorage.setItem('lang', temp);
        if (text) {
          this.loadingController.dismiss();
          this.navCtrl.navigateForward(['login']);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  authenticated(): boolean {
    return this.afAuth.authState !== null;
  }

  authState() {
    return this.afAuth.authState;
  }

  currentUserObservable(): any {
    return this.afAuth.authState;
  }

  currentUser() {
    this.afAuth.currentUser;
  }

  getAFAuth() {
    return this.afAuth;
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user !== null ? true : false;
  }

  async newPassword(newPassword: string) {
    return this.afAuth.currentUser.then(user => user?.updatePassword(newPassword));
  }

  async newEmail(newEmail: string) {
    return this.afAuth.currentUser.then(user => user?.updateEmail(newEmail));
  }

  async updateCurrentUserName(name: string) {
    return this.afAuth.currentUser.then(user =>
      user?.updateProfile({
        displayName: name,
      })
    );
  }

  async passwordRemind(email: string) {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  createUserWithEmailAndPassword(email: string, password: string): Promise<any> {
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  async createUser(email: string, password: string, name?: string) {
    return this.afAuth.createUserWithEmailAndPassword(email, password).then(result => {
      if (name) {
        this.updateCurrentUserName(name);
      }
      return result.user;
    });
  } */
}
