import { Component, EnvironmentInjector, inject } from '@angular/core';
import { IonicModule, Platform } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { firstValueFrom } from 'rxjs';
import { AuthService } from './shared/services/auth.service';
import { LoggerService } from './shared/services/logger.service';
import { NetworkService } from './shared/services/network.service';
import { FcmService } from './shared/services/fcm.service';
import { PrevRouteService } from './shared/services/prev-route.service';
import { TitleService } from './shared/services/title.service';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule],
})
export class AppComponent {
  public environmentInjector = inject(EnvironmentInjector);

  constructor(
    private platform: Platform,
    private networkService: NetworkService,
    private authService: AuthService,
    private logger: LoggerService,
    private fcmService: FcmService,
    private appVersion: AppVersion,
    private titleService: TitleService,
    private prevRouteService: PrevRouteService,
    private statusBar: StatusBar
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    // this.tr.use(null!);
    // this.logger.setCookie(false);
    this.platform.ready().then(async () => {
      this.titleService.refreshTitle();
      this.prevRouteService.loadRouting();
      this.networkService.checkConnectedStatus();
      this.networkService.listenForConnectionChanges();
      try {
        const user: any = await firstValueFrom(this.authService.currentUserObservable());
        if (user) {
          this.logger.setEmail(user.email);
          this.logger.setUserId(user.uid);
          this.fcmService.checkNotifications();
        }
        if (this.platform.is('cordova')) {
          if (this.platform.is('ios')) {
            this.statusBar.styleDefault();
          }
          const versionCode = await this.appVersion.getVersionCode();
          this.logger.setVersionCode(versionCode);
          const versionNumber = await this.appVersion.getVersionNumber();
          this.logger.setVersionNumber(versionNumber);
          const packageName = await this.appVersion.getPackageName();
          this.logger.setPackageName(packageName);
          const appName = await this.appVersion.getAppName();
          this.logger.setAppName(appName);
        }
      } catch (error) {
        this.logger.handleError(error);
      }
    });
  }
}
